<template>
  <div class="outermost">
    <layout class="layout" :hd="true" :ft="false">
      <!-- 右边按键 -->
      <div slot="rt"></div>
      <!-- 左边按键 -->
      <div slot="le" class="hd-left" @click="returnHome">
        <div></div>
      </div>
      <div slot="title">
        <span>课程列表</span>
      </div>
      <!-- 列表 -->
      <van-pull-refresh
        v-model="isLoading"
        @refresh="onRefresh"
        :success-text="refreshSuccessText"
        :success-duration="1500"
      >
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="暂无更多"
          @load="onLoad"
        >
          <div
            class="list-background"
            v-for="item in list"
            :key="item.id"
            @click="jumpDetails(item)"
          >
            <div class="list-content">
              <div class="list-content-title">
                {{ item.course_title }}
              </div>
              <div class="list-content-tiem">{{ item.start_time }}</div>
            </div>
            <div class="list-img">
              <img :src="item.picture_data[0]" alt="" />
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </layout>
  </div>
</template>

<script>
import { getCouresList } from '@/api/course'
import Layout from '@/components/layout.vue'
export default {
  components: {
    Layout
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      isLoading: false,
      pageNo: 1,
      pageSize: 10,
      refreshSuccessText: '刷新成功',
      title: ''
    }
  },
  created() {},
  methods: {
    getTitle(type) {
      switch (type) {
        case 1:
          return '军创俱乐部'
        case 2:
          return '营销沙龙'
        case 3:
          return '下午茶'
        case undefined:
          return '分类列表'
      }
    },
    async onLoad() {
      try {
        // 1. 请求获取数据
        const result = await getCouresList({
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          column: 'createTime',
          order: 'desc'
        })

        const list = result.result.records
        if (this.$route.params.type === 'skill') {
          const filterList = list.filter((item) => {
            if (
              (item.course_type == 0 ||
                item.course_type == 1 ||
                item.course_type == 2) &&
              item.course_status === 1
            ) {
              return true
            } else {
              return false
            }
          })

          filterList.forEach((item) => {
            item.picture_data = item.picture_data.split(',')
          })
          this.list.push(...filterList)
        } else if (this.$route.params.type === 'adult') {
          const filterList = list.filter((item) => {
            if (
              (item.course_type == 3 || item.course_type == 4) &&
              item.course_status === 1
            ) {
              return true
            } else {
              return false
            }
          })

          filterList.forEach((item) => {
            item.picture_data = item.picture_data.split(',')
          })
          this.list.push(...filterList)
        }

        this.loading = false

        if (result.result.records.length) {
          this.pageNo++
        } else {
          this.finished = true
        }
      } catch (err) {
        this.loading = false
      }
    },
    onRefresh() {
      this.list = []
      this.pageNo = 1
      this.onLoad()
    },
    // 跳转详情
    jumpDetails(item) {
      this.$router.push({
        name: 'curriculum-details',
        params: {
          id: item.id
        }
      })
    },
    // 返回上一页
    returnHome() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less" scoped>
.outermost {
  background-color: #f2f3f5;
  .layout /deep/ .main {
    padding-bottom: 0;
  }
  .hd-left {
    display: flex;
    align-items: center;
    div {
      width: 10px;
      height: 10px;
      border-top: 2px solid #ffffff;
      border-right: 2px solid #ffffff;
      transform: rotate(225deg);
    }
    span {
      font-size: 18px;
      margin-left: 4px;
    }
  }
  .list-background {
    margin: 0 10px;
    margin-top: 8px;
    margin-bottom: 2px;
    height: 92px;
    background: #ffffff;
    border-radius: 8px;
    padding: 15px 14px;
    display: flex;
    justify-content: space-between;
    .list-img {
      width: 150px;
      height: 100%;
      img {
        border-radius: 4px;
        width: 100%;
        height: 100%;
      }
    }
    .list-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .list-content-title {
        width: 164px;
        font-size: 15px;
        color: #12151b;
        line-height: 1.2;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
      }
      .list-content-tiem {
        font-size: 12px;
        color: #464a55;
      }
    }
  }
}
</style>
