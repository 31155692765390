<template>
  <div>
    <curriculumList v-if="!$store.state.user.elderModel" />
    <curriculumListElder v-else />
  </div>
</template>

<script>
import curriculumList from './curriculumList.vue'
import curriculumListElder from './curriculumList-elder.vue'
export default {
  name: '',
  components: {
    curriculumList,
    curriculumListElder
  },
  data() {
    return {}
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style  scoped>
</style>

