import request from '@/utils/request'

export function getCouresList(params) {
    return request({
        url: '/lowcode/online/cgform/api/getData/af62742e78394fde9cd8f537114fea4a',
        method: 'get',
        params
    })
}
export function getCouresDetails(url) {
    return request({
        url: `/lowcode/trainingCourses/info/${url}`,
        method: 'get',
    })
}
export function openApply(data) {
    return request({
        url: '/lowcode/trainingCourseReservation/add',
        method: 'POST',
        data
    })
}
export function pageviewCourse(params) {
    return request({
        url: '/lowcode/trainingCourses/courseView',
        method: 'get',
        params
    })
}